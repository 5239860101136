import {call_post, SHAPE_API_THEN, usingCancelToken} from "./api";
import {
    crmForgotPassword,
    crmLogin,
    crmLogout,
    crmResetPassword,
    crmResetPasswordValidateToken,
    crmUserDetail
} from "./root";
import * as notify from "./notification"

import {
    removeAccessToken,
    removeUserAccount,
    saveAccessToken,
    saveUserAccount
} from "../config/authentication";
import {goToLogin} from "../config/movePage";

export const authLogin = (data) => {
    return SHAPE_API_THEN(call_post(crmLogin, data), true)
        .then(async (resData) => {

            if (resData.results && resData.results.token && resData.results.token.accessToken) {
                await saveAccessToken(resData.results.token.accessToken)
            }

            return resData

        }).catch((err) => {
            console.log(err)
            notify.error(err.message)
        })
}

export const getUserDetail = () => {
    return SHAPE_API_THEN(call_post(crmUserDetail), true)
        .then((resData) => {

            if (resData.results && resData.results.user) {
                saveUserAccount(resData.results.user)
            }

            return resData
        })
        .catch((err) => {
            console.log(err)
            notify.error(err.message)
        })
}

export const authLogout = () => {
    return SHAPE_API_THEN(call_post(crmLogout), true)
        .then(async (resData) => {
            if (resData.status === 'success') {
                await removeAccessToken()
                await removeUserAccount()
                await goToLogin()
            }
            return resData
        })
        .catch((err) => {
            console.log(err)
            notify.error(err.message)
        })
}

export const authForgotPassword = (data) => {
    const others = {...usingCancelToken("tokenAuthForgotPassword")}
    return SHAPE_API_THEN(call_post(crmForgotPassword, data, others), true, true)
}

export const authValidateResetPasswordToken = (data) => {
    const others = {...usingCancelToken("tokenAuthValidationResetPasswordToken")}
    return SHAPE_API_THEN(call_post(crmResetPasswordValidateToken, data, others), true, true)
}

export const authResetPassword = (data) => {
    const others = {...usingCancelToken("tokenAuthResetPassword")}
    return SHAPE_API_THEN(call_post(crmResetPassword, data, others), true, true)
}
