import {format} from "date-fns";
import {call_get, download_file, SHAPE_API_THEN, usingCancelToken} from "./api";
import {
    crmTransactionAll,
    crmTransactionAllDownload,
    crmTransactionAlterra,
    crmTransactionAlterraDownload,
    crmTransactionDana,
    crmTransactionDanaDownload,
    crmTransactionGopay,
    crmTransactionGopayDownload,
    crmTransactionMidtrans,
    crmTransactionMidtransDownload
} from "./root";

export function getAllTransactions(params) {
    const others = {...usingCancelToken("tokenGetAllTransactions")}
    return SHAPE_API_THEN(call_get(crmTransactionAll, params, others), true, true)
}

export function downloadAllTransactions(params) {

    const filename = 'all_transactions-' + format(new Date(), 'dd-MM-yyyy') + '.xlsx'
    const others = {...usingCancelToken("tokenDownloadAllTransactions")}

    return SHAPE_API_THEN(download_file(crmTransactionAllDownload, params, filename, others), true, true)
}

export function getAlterraTransactions(params) {
    const others = {...usingCancelToken("tokenGetAlterraTransactions")}
    return SHAPE_API_THEN(call_get(crmTransactionAlterra, params, others), true, true)
}

export function downloadAlterraTransactions(params) {

    const filename = 'alterra_transactions-' + format(new Date(), 'dd-MM-yyyy') + '.xlsx'
    const others = {...usingCancelToken("tokenDownloadAlterraTransactions")}

    return SHAPE_API_THEN(download_file(crmTransactionAlterraDownload, params, filename, others), true, true)
}

export function getDanaTransactions(params) {
    const others = {...usingCancelToken("tokenGetDanaTransactions")}
    return SHAPE_API_THEN(call_get(crmTransactionDana, params, others), true, true)
}

export function downloadDanaTransactions(params) {

    const filename = 'dana_transactions-' + format(new Date(), 'dd-MM-yyyy') + '.xlsx'
    const others = {...usingCancelToken("tokenDownloadAlterraTransactions")}

    return SHAPE_API_THEN(download_file(crmTransactionDanaDownload, params, filename, others), true, true)
}

export function getGopayTransactions(params) {
    const others = {...usingCancelToken("tokenGetGopayTransactions")}
    return SHAPE_API_THEN(call_get(crmTransactionGopay, params, others), true, true)
}

export function downloadGopayTransactions(params) {

    const filename = 'gopay_go_tagihan_transactions-' + format(new Date(), 'dd-MM-yyyy') + '.xlsx'
    const others = {...usingCancelToken("tokenDownloadGopayTransactions")}

    return SHAPE_API_THEN(download_file(crmTransactionGopayDownload, params, filename, others), true, true)
}

export function getMidtransTransactions(params) {
    const others = {...usingCancelToken("tokenGetGopayTransactions")}
    return SHAPE_API_THEN(call_get(crmTransactionMidtrans, params, others), true, true)
}

export function downloadMidtransTransactions(params) {

    const filename = 'midtrans_transactions-' + format(new Date(), 'dd-MM-yyyy') + '.xlsx'
    const others = {...usingCancelToken("tokenDownloadMidtransTransactions")}

    return SHAPE_API_THEN(download_file(crmTransactionMidtransDownload, params, filename, others), true, true)
}
