import axios from "axios";
import * as notify from "./notification";
import {encryptCode, STORAGE_ACCESS_TOKEN} from "../config/authentication";

const keyCancel = {}
const CancelToken = axios.CancelToken;

export function usingCancelToken(parentName) {
    if (keyCancel[parentName]) {
        keyCancel[parentName]()
    }

    return {
        cancelToken: new CancelToken(function executor(c) {
            keyCancel[parentName] = c;
        })
    }
}

export function argCancelToken(err) {
    if (axios.isCancel(err)) {
        return {
            status: 'cancel',
        }
    }
}

export function argCatchMsg(err) {
    if (err !== null && err.message && err.message !== undefined) {
        return true
    }
    return false
}

export const configHeaderBody = () => {

    const accessToken = window.localStorage.getItem(STORAGE_ACCESS_TOKEN) || ''

    return {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
        'Secret': encryptCode()
    }

}

export async function get(url, params, others, headers = {}) {
    return await axios({
        method: 'get',
        url: url,
        params: params,
        headers: {
            ...headers
        },
        ...others
    })
}

export async function post(url, data, other, headers = {}) {
    return await axios({
        method: 'POST',
        url: url,
        data: data,
        headers: {
            ...headers
        },
        withCredentials: false,
        ...other
    })
}

export function call_post(url, data = {}, others = {}) {
    return post(url, data, others, configHeaderBody())
}

export function call_get(url, params, others = {}) {
    return get(url, params, others, configHeaderBody())
}

export function download_file(url, params = {}, filename = 'transaction.xlsx', others = {}) {

    others = {
        ...others,
        responseType: 'blob'
    }

    return get(url, params, others, configHeaderBody())
        .then((res) => {

            if (res.data) {

                const url = window.URL.createObjectURL(new Blob([res.data]))

                const tagA = document.createElement('a');
                tagA.href = url
                tagA.download = filename
                tagA.click();

                window.URL.revokeObjectURL(url);

            }

            return res.data

        })
        .catch((err) => {
            notify.error(err.message)
        })
}

export function SHAPE_API_THEN(funcUrl, isUsingNotification = true, isUseToken = false) {
    return funcUrl
        .then((res) => {

            if (isUsingNotification) {
                if (res.data && res.data.status && (res.data.status === 'error')) {
                    notify.error(res.data.errors.msg)
                }
            }

            return res.data

        }).catch((err) => {
            console.log(err)
            if (isUseToken && argCatchMsg(err)) {
                notify.error(err.message)
            } else {
                return argCancelToken(err)
            }
        })
}
